import React from 'react';

import { Link } from 'react-router-dom';

import Quote from '../components/Quote';
import Highlight from '../components/Highlight';

import Button from '../../../components/Button';
import theme from '../../../styles/theme';
import Helmet from '../../../components/Helmet';

export default function Subscribers() {
  return (
    <>
      <Helmet title="Novidades" description="Saiba mais sobre as novidades" />

      <div className="post">
        <h1>Novidades 2020 - Novo sistema</h1>

        <Quote color="gold">
          <p>
            Faça o login com sua conta da{' '}
            <strong className="color-twitch">Twitch</strong>! Isso irá criar o
            seu usuário no novo sistema e você estará apto a receber os
            benefícios.
          </p>

          <h3>
            NÃO FAÇA UM SUB, RESUB OU DOAÇÃO ANTES DE ENTRAR NO SISTEMA E LOGAR
            PELA PRIMEIRA VEZ.
          </h3>

          <p>
            Depois de feito o login pela primeira vez no site, seu usuário já
            estará criado e você pode ficar tranquilo, fazer subs e doações
            normalmente sem se preocupar. Note que este procedimento é
            necessário apenas no <strong>primeiro login</strong>.
          </p>
        </Quote>

        <h3>
          Novo sistema de sorteios e{' '}
          <span className="color-ticket">tickets</span>
        </h3>

        <p>
          No novo sistema qualquer pessoa que tenha um{' '}
          <strong className="color-ticket">ticket</strong> participa de qualquer
          sorteio ativo. Você pode ganhar{' '}
          <strong className="color-ticket">tickets</strong> dando{' '}
          <strong className="color-twitch">subscribers</strong> na live ou
          fazendo doações.
        </p>

        <p>
          Os sorteios abertos a todos continuam e agora são feitos diretamente
          no chat durante as lives. <strong>Fiquem ligados</strong>.
        </p>

        <h3>
          Novo sistema de pontos e <span className="color-gold">gold</span>
        </h3>

        <p>
          Resumindo, você ganha <strong>2 pontos</strong> a cada 10 minutos de
          live assistidos e estes pontos podem ser utilizados para participar de
          sorteios abertos no chat, jogos na live ou trocá-los por{' '}
          <strong className="color-gold">gold</strong> em nosso sistema.
        </p>

        <p>
          <strong className="color-gold">Golds</strong> são utilizados como
          moeda na <Link to="/loja">Loja</Link> para comparar produtos.
        </p>

        <Highlight>
          <Button
            to="/como-funciona"
            color={theme.colors.translucentDark}
            text={theme.colors.primary}
            pulse
          >
            Clique aqui para saber mais sobre o sistema
          </Button>
        </Highlight>
      </div>
    </>
  );
}
