import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { joinGiveawayRequest } from '../../../../store/modules/giveaway/actions';

import _ from 'lodash';
import { TabBar } from '@rmwc/tabs';

import { Container, Tab } from './styles';

import Button from '../../../../components/Button';
import Spinner from '../../../../components/Spinner';

import theme from '../../../../styles/theme';

export default function Info() {
  const [tabIndex, setTabIndex] = useState(0);
  const [tickets, setTickets] = useState({ unique: [], common: [] });
  const [usedTickets, setUsedTickets] = useState(null);
  const [hasJoinedAsUnique, setHasJoinedAsUnique] = useState(null);

  const { verifying, verified } = useSelector(state => state.verification);
  const { profile } = useSelector(state => state.user);
  const { details } = useSelector(state => state.giveaway);
  const { defaults } = useSelector(state => state.info);

  const { giveaway_id } = useParams();

  const dispatch = useDispatch();

  const commonTicketsRef = useRef(null);

  function handleJoin(type) {
    const commonTickets =
      commonTicketsRef.current && commonTicketsRef.current.value;
    dispatch(joinGiveawayRequest(giveaway_id, type, commonTickets));
  }

  function InfoContent() {
    return (
      <>
        <p>
          Para participar do sorteio. você precisa adquirir{' '}
          <strong className="color-ticket">tickets</strong>. Existem várias
          formas de conseguir <strong className="color-ticket">tickets</strong>,
          sendo elas:
        </p>

        <div>
          <h2>
            Sendo um <strong className="color-twitch">subscriber</strong>
          </h2>
          <p>
            Ao se tornar um <strong className="color-twitch">subscriber</strong>{' '}
            você recebe <strong className="color-ticket">tickets</strong> para
            participar dos sorteios. A quantidade de{' '}
            <strong className="color-ticket">tickets</strong> pode variar de
            acordo com o seu <strong>tier</strong>.
          </p>
          <Button
            pulse
            url={`https://www.twitch.tv/subs/${process.env.REACT_APP_CHANNEL_NAME}`}
            color={theme.colors.twitch}
            text={theme.colors.text}
          >
            <span>Tornar-se um subscriber</span>
          </Button>
        </div>

        <div>
          <h2>
            Fazendo uma <strong className="color-slabs">doação</strong>
          </h2>
          <p>
            A cada{' '}
            <strong className="color-slabs">
              R${(defaults && defaults.tickets.donation) || '10'}
            </strong>{' '}
            doados você recebe 1{' '}
            <strong className="color-ticket">ticket</strong>
          </p>
          <Button
            pulse
            url={`https://streamlabs.com/${process.env.REACT_APP_CHANNEL_NAME}/tip`}
            color={theme.colors.slabs}
            text={theme.colors.text}
          >
            <span>Fazer uma doação</span>
          </Button>
        </div>

        <div>
          <p className="center">
            Para mais detalhes, acesse o <strong>Como Funciona</strong>
          </p>
          <Button
            color={theme.colors.verified}
            text={theme.colors.text}
            to="/como-funciona/sorteios"
          >
            <span>Mais detalhes</span>
          </Button>
        </div>
      </>
    );
  }

  function GiveawayContent() {
    return (
      <div className="giveaway">
        {profile ? (
          <>
            {!!usedTickets && (
              <p className="giveaway_ticket-count">
                Você está participando com{' '}
                <strong className="color-ticket">
                  {usedTickets > 1
                    ? `${usedTickets} tickets`
                    : `${usedTickets} ticket`}
                </strong>
              </p>
            )}
            <div className="giveaway__unique">
              {!!tickets.unique.length && (
                <p>
                  Você tem{' '}
                  <strong className="color-twitch">
                    {tickets.unique.length}{' '}
                    {tickets.unique.length > 1
                      ? 'tickets únicos'
                      : 'purple ticket'}
                  </strong>
                </p>
              )}
              {hasJoinedAsUnique && (
                <Button
                  color={theme.colors.twitch}
                  text={theme.colors.text}
                  disabled
                >
                  Você já usou um purple ticket
                </Button>
              )}
              {!hasJoinedAsUnique && !!tickets.unique.length && (
                <Button
                  color={theme.colors.twitch}
                  text={theme.colors.text}
                  onClick={() => handleJoin('unique')}
                  pulse
                >
                  Participar com purple ticket
                </Button>
              )}
            </div>

            {(!!tickets.unique.length || hasJoinedAsUnique) &&
              !!tickets.common.length && <h3 className="or">OU</h3>}

            {!!tickets.common.length && (
              <div className="giveaway__common">
                <p>
                  Você tem{' '}
                  <strong className="color-ticket">
                    {tickets.common.length} tickets
                  </strong>
                </p>
                <div className="giveaway__common-action">
                  <input
                    type="number"
                    min="1"
                    max="9999"
                    defaultValue="1"
                    ref={commonTicketsRef}
                  />
                  <Button
                    color={theme.colors.ticket}
                    text={theme.colors.text}
                    onClick={() => handleJoin('common')}
                    pulse={!hasJoinedAsUnique}
                  >
                    Participar
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <h3>Você precisa estar logado para participar</h3>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (!profile || !profile.tickets) return;

    const [unique, common] = _.partition(profile.tickets, 'is_unique');
    setTickets({ unique, common });
  }, [profile]);

  useEffect(() => {
    if (!details.tickets) return;
    setHasJoinedAsUnique(!!details.tickets.filter(t => t.is_unique).length);
    setUsedTickets(details.tickets.length);
  }, [details.tickets]);

  return (
    <Container>
      <TabBar
        activeTabIndex={tabIndex}
        onActivate={e => setTabIndex(e.detail.index)}
      >
        <Tab label="Sorteio" />
        <Tab label="Como participar" />
      </TabBar>
      <div className="content">
        {verifying && !verified ? (
          <div className="verifying">
            <Spinner size={32} />
            <p>Verificando dados...</p>
          </div>
        ) : tabIndex ? (
          <InfoContent />
        ) : (
          <GiveawayContent />
        )}
      </div>
    </Container>
  );
}
