import styled from '@xstyled/styled-components';

import { transparentize } from 'polished';

import theme from '../../styles/theme';

import Novidades from '../../assets/logo_big.png';
import Sorteios from '../../assets/home/sorteios.png';
import Loja from '../../assets/home/loja.png';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
`;

export const Grid = styled.div`
  display: flex;
  height: 100%;
  align-items: stretch;

  h2 {
    text-align: center;
    font-size: 3rem;
    font-family: 'Nunito';
    letter-spacing: 3px;
  }

  .card {
    padding: 2rem;
    border: 2px solid;
    border-color: shapesDark;
    border-radius: default;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
      to top,
      ${transparentize(0.1, theme.colors.shapesDark)} 20%,
      ${transparentize(0.7, theme.colors.shapesDark)}
    );

    :hover {
      color: inherit;
      border-color: gold;
      transform: translateY(-2px);

      &.giveaways {
        border-color: giveaway;
      }

      &.shop {
        border-color: gift;
      }
    }
  }

  .left {
    flex: 1;
    margin-right: 0.5rem;

    background-image: linear-gradient(
        to bottom,
        ${transparentize(0.2, theme.colors.shapesDark)} 20%,
        ${theme.colors.shapesDark}
      ),
      url(${Novidades});
    background-size: cover;
    background-repeat: no-repeat;
  }

  .right {
    flex: 1;
    margin-left: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .giveaways {
      margin-bottom: 0.5rem;
      background-image: linear-gradient(
          to bottom,
          ${transparentize(0.5, theme.colors.shapesDark)} 20%,
          ${theme.colors.shapesDark}
        ),
        url(${Sorteios});
    }

    .shop {
      margin-top: 0.5rem;
      background-image: linear-gradient(
          to bottom,
          ${transparentize(0.5, theme.colors.shapesDark)} 20%,
          ${theme.colors.shapesDark}
        ),
        url(${Loja});
    }
  }
`;
