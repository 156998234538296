import styled, { css, keyframes } from '@xstyled/styled-components';
import { transparentize, adjustHue } from 'polished';
import { easeOutBack } from 'eazy-ease';

import { ProgressBar as StepProgressBar } from 'react-step-progress-bar';

import theme from '../../../../styles/theme';

const fade = keyframes`

  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);

  }

`;

const pulse = color => keyframes`

  0% {
    box-shadow: 0 0 0 0 ${transparentize(0.6, `${color}`)};
  }


  40% {
    box-shadow: 0 0 0 12px ${transparentize(1, `${color}`)};
  }

`;

export const Container = styled.div`
  overflow: hidden;
  min-height: 100%;
  width: 100%;

  .step {
    cursor: pointer;
    font-size: 1.4rem;
    height: 4.2rem;
    width: 4.2rem;
    background-color: shapesDark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    &.accomplished {
      background-color: primary;
    }
  }

  ${({ background }) =>
    background &&
    css`
      padding: huge;
      background-image: linear-gradient(
          to top,
          ${theme.colors.shapes},
          transparent
        ),
        linear-gradient(to left, ${theme.colors.shapes}, transparent),
        url(${background});
      background-size: cover;
    `}
`;

export const Content = styled.div`
  margin-top: 6rem;
  animation: ${fade} 1.4s ${easeOutBack};

  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    letter-spacing: 2px;
    font-size: 4rem;
    text-transform: uppercase;
  }

  header {
    p {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;

      strong {
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.6rem;
        }
      }

      span {
        margin-left: 0.6rem;
      }
    }
    img {
      height: 180px;
      border-radius: default;

      animation: ${pulse(theme.colors.verified)} 5s infinite;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 2rem 0;
    }

    .manual {
      display: flex;

      input {
        font-size: 1.4rem;
        width: 8rem;
      }

      button {
        margin-left: 1rem;
      }
    }

    strong {
      color: primary;
      margin-top: 2rem;
    }
  }

  .winner {
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
      margin-bottom: 4rem;
      p {
        margin: 0;
      }

      h1 {
        margin: 2rem 0;
      }
    }

    p {
      margin-top: 2rem;
      font-size: 3rem;
    }

    .actions {
      margin-top: 3rem;
    }
  }

  > * + * {
    margin-top: 3rem;
  }
`;

export const ProgressBar = styled(StepProgressBar).attrs(({ color }) => ({
  filledBackground: `linear-gradient(to right, ${adjustHue(
    -20,
    color
  )}, ${color})`,
}))``;
