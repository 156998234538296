import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Grid } from './styles';

import Helmet from '../../components/Helmet';

export default function Home() {
  return (
    <Container>
      <Helmet title="Home" description="Página principal" />
      <Grid>
        <Link to="/como-funciona/novidades" className="card left">
          <h2>Saiba mais sobre as novidades</h2>
        </Link>
        <div className="right">
          <Link to="/sorteios" className="card giveaways">
            <h2>Participe dos sorteios</h2>
          </Link>
          <Link to="/loja" className="card shop">
            <h2>Acesse a loja</h2>
          </Link>
        </div>
      </Grid>
    </Container>
  );
}
