import React from 'react';

import { Link } from 'react-router-dom';

import Quote from '../components/Quote';
import Highlight from '../components/Highlight';

import Button from '../../../components/Button';

import { FaArrowRight } from 'react-icons/fa';

import Love from '../../../assets/emotes/love.png';

import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Subscribers() {
  return (
    <>
      <Helmet
        title="Subscribers"
        description="Por que ser um Subscriber do Peronio?"
      />
      <div className="post">
        <h1>Por que ser um Subscriber? 🤔</h1>

        <Quote>
          O subscriber é o “sálario” do streamer, dependemos da sua ajuda para
          manter as lives
        </Quote>

        <h3>
          <span className="color-twitch">Subscribers</span> e Twitch{' '}
          <span className="color-prime">Prime</span>
        </h3>

        <p>
          Qualquer subscriber da live tem os <strong>mesmos benefícios</strong>,
          incluindo os subscribers via{' '}
          <strong>
            Twitch <span className="color-prime">Prime</span>
          </strong>
        </p>

        <Highlight>
          <Button
            url="https://twitch.amazon.com/tp"
            color={theme.colors.translucentDark}
            text={theme.colors.prime}
          >
            Clique aqui para saber as vantagens da Twitch Prime
          </Button>
        </Highlight>

        <h3>Sorteios, sorteios e mais sorteios!</h3>

        <p>
          O{' '}
          <Link to="/como-funciona/sorteios">
            novo sistema de sorteios da live
          </Link>{' '}
          permite que um <strong className="color-twitch">subscriber</strong>{' '}
          entre em, <strong>no mínimo, 10 sorteios todos os meses</strong>,
          podendo escolher <strong>qualquer sorteio</strong>, sem nenhuma
          limitação.
        </p>

        <h3 className="color-twitch">Purple tickets</h3>

        <p>
          Ao se tornar um <strong className="color-twitch">subscriber</strong>,
          seja{' '}
          <strong>
            Twitch <span className="color-prime">Prime</span>
          </strong>{' '}
          ou sub comum, você ganha automaticamente{' '}
          <strong className="color-twitch">purple tickets</strong> que lhe
          permitem participar de <strong>qualquer sorteio</strong> ativo na{' '}
          <Link to="/sorteios">página de sorteios da live</Link>.
        </p>

        <p>
          Quanto maior o seu <strong>tier</strong> de subscriber, mais{' '}
          <strong className="color-twitch">purple tickets</strong> você ganha,
          abaixo a tabela explicativa:
        </p>

        <Highlight>
          <ul className="center">
            <li>
              <strong className="color-twitch">Subs Tier 1</strong> (ou{' '}
              <strong>
                Twitch <span className="color-prime">Prime</span>
              </strong>
              ) <FaArrowRight size={12} />{' '}
              <strong>
                10 <span className="color-twitch">purple tickets</span>
              </strong>
            </li>
            <li>
              <strong className="color-twitch">Subs Tier 2</strong>{' '}
              <FaArrowRight size={12} />{' '}
              <strong>
                20 <span className="color-twitch">purple tickets</span>
              </strong>
            </li>
            <li>
              <strong className="color-twitch">Subs Tier 3</strong>{' '}
              <FaArrowRight size={12} />{' '}
              <strong>
                30 <span className="color-twitch">purple tickets</span>
              </strong>
            </li>
          </ul>
        </Highlight>

        <Quote>
          Você recebe os tickets toda vez que ativar seu subscribe e/ou quando
          enviar o alerta de resubscribe.
        </Quote>

        <h3>
          Troca de pontos e <strong className="color-gold">gold</strong> na loja
          com benefícios
        </h3>

        <p>
          Uma pessoa que não é{' '}
          <strong className="color-twitch">subscriber</strong> troca normalmente{' '}
          <strong>20 pontos</strong> por cada{' '}
          <strong className="color-gold">gold</strong> para trocar por produtos
          na loja , mas os <strong className="color-twitch">subscribers</strong>{' '}
          tem vantagem! Abaixo a tabela:
        </p>

        <Highlight>
          <ul className="center">
            <li>
              <strong className="color-twitch">Subs Tier 1</strong> (ou{' '}
              <strong>
                Twitch <span className="color-prime">Prime</span>
              </strong>
              ) trocam: <strong>10 pontos</strong> <FaArrowRight size={12} />{' '}
              <strong>
                1 <span className="color-gold">gold</span>
              </strong>
            </li>
            <li>
              <strong className="color-twitch">Subs Tier 2</strong> trocam:{' '}
              <strong>5 pontos</strong> <FaArrowRight size={12} />{' '}
              <strong>
                1 <span className="color-gold">gold</span>
              </strong>
            </li>
            <li>
              <strong className="color-twitch">Subs Tier 3</strong> trocam:{' '}
              <strong>3 pontos</strong> <FaArrowRight size={12} />{' '}
              <strong>
                1 <span className="color-gold">gold</span>
              </strong>
            </li>
          </ul>
        </Highlight>

        <Highlight>
          <Button
            to="/como-funciona/gold"
            color={theme.colors.translucentDark}
            text={theme.colors.gold}
          >
            Clique aqui para saber mais sobre pontos e gold
          </Button>
        </Highlight>

        <h3>
          Área exclusiva no <span className="color-discord">Discord</span> da
          live
        </h3>

        <p>
          <strong className="color-twitch">Subscribers</strong> tem acesso a uma
          área exclusiva do{' '}
          <a className="color-discord" href="http://discord.gg/carlinhostroll">
            <strong className="color-discord">Discord</strong>
          </a>{' '}
          da live. Para tal, basta linkar seu{' '}
          <strong className="color-discord">Discord</strong> com sua{' '}
          <strong className="color-twitch">Twitch</strong>.
        </p>

        <h3>Dia dos subs na live!</h3>

        <p>
          Os <strong className="color-twitch">subscribers</strong> da live tem
          um dia exclusivo, onde podem votar, jogar e opinar sobre o conteúdo
          inteiro da live. Fiquem ligados no calendário para saber o próximo.
        </p>

        <h3>
          Presenteando com <span className="color-twitch">subscribers</span>{' '}
          você também ganha!
        </h3>

        <p>
          A cada vez que você presenteia uma ou mais pessoas na live com um
          Subscribe, você ganha automaticamente em sua conta{' '}
          <strong>
            1 <span className="color-ticket">ticket</span>
          </strong>{' '}
          para participar de qualquer sorteio.
        </p>

        <p>
          O pessoa presenteada também ganha os benefícios de{' '}
          <strong className="color-twitch">subscriber</strong> como se tivesse
          adquirido um sub normalmente.
        </p>

        <Quote color="error">
          Para receber os benefícios do seu subscriber no sistema, ao fazer um{' '}
          <strong>resub</strong> você precisa ativar o alerta do topo do chat da{' '}
          <strong className="color-twitch">Twitch</strong> (onde você
          compartilha uma mensagem). Caso contrário, seus benefícios não
          entrarão automaticamente.
        </Quote>

        <Highlight>
          <p>
            <strong>Obrigado por colaborar com a live!</strong>
          </p>
          <p>
            <img src={Love} width={32} />
          </p>
        </Highlight>
      </div>
    </>
  );
}
