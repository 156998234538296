import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FaCheck, FaWrench } from 'react-icons/fa';

import { Container } from './styles';

const Avatar = forwardRef(({ url, admin, verified, size, ...rest }, ref) => {
  const Icon = admin ? FaWrench : verified ? FaCheck : null;

  return (
    <Container
      ref={ref}
      admin={admin}
      verified={verified}
      size={size}
      {...rest}
    >
      {(verified || admin) && (
        <div className="icon">
          <Icon size={size / 6} />
        </div>
      )}
      <img src={url} alt="avatar" />
    </Container>
  );
});

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  verified: PropTypes.bool,
  size: PropTypes.number,
};
Avatar.defaultProps = {
  admin: false,
  verified: false,
  size: 46,
};

export default memo(Avatar);
