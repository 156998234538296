import React from 'react';

import Helmet from '../../../components/Helmet';

export default function Account() {
  return (
    <>
      <Helmet
        title="Subscribers"
        description="Por que ser um Subscriber do Peronio?"
      />
      <div className="post">
        <h1>Sua conta em nosso sistema</h1>

        <p>
          Sua conta é onde ficam todas as informações que você precisa sobre
          nosso novo sistema. Nela você terá acesso as seus pedidos na loja,
          sorteios que você precisa resgatar ou receber, histórico de tudo o que
          você andou fazendo em nosso sistema, configurações de aparência,
          ativar códigos recebidos e muito mais.
        </p>

        <p>
          Você também pode verificar quantos <strong>pontos</strong>,{' '}
          <strong className="color-gold">golds</strong>,{' '}
          <strong className="color-ticket">tickets</strong> e{' '}
          <strong className="color-gift">gifts</strong> possui diretamente na
          barra superior do sistema.
        </p>

        <h3>Usuários verificados</h3>

        <p>
          Nosso sistema possui uma{' '}
          <strong className="color-verified">verificação de usuários</strong>,
          onde após ser verificado, um usuário tem prazos de recebimento
          diferenciados para compras na loja ou premiações de sorteios.
        </p>

        <p>
          Um usuário <strong className="color-verified">verificado</strong>, ao
          adquirir um produto digital na loja (keys de jogos, por exemplo) ou
          ganhá-lo em um sorteio, recebe a mesma{' '}
          <strong>instantaneamente</strong> após o resgate ou compra.
        </p>

        <p>
          Caso o produto seja físico (que exija envio postal convencional), seja
          ele adquirido na loja ou recebido de premiação, o usuário{' '}
          <strong className="color-verified">verificado</strong> tem o envio do
          produto feito em até 5 dias úteis após o recebimento dos dados para
          envio.
        </p>

        <h3>Como eu me torno um usuário verificado?</h3>

        <p>
          Hoje o sistema de verificação da live é feito manualmente para
          usuários específicos e não existe uma fórmula pronta para verificação.
          Os <strong className="color-verified">verificados</strong> serão
          distribuídos aos poucos e para poucos, mas com o avançar do tempo este
          sistema vai se tornar cada vez mais abrangente.
        </p>
      </div>
    </>
  );
}
