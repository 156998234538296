import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import api from '../../../../services/api';
import EventItem from './EventItem';

import Search from '../../../../components/admin/Search';
import Select from '../../../../components/Select';

import { format } from 'date-fns';

export default function Events() {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [type, setType] = useState(null);

  function handleSearch(value) {
    setSearch(value);
  }

  useEffect(() => {
    async function getEvents() {
      const response = await api.get('/admin/events', {
        params: {
          search,
          type,
        },
      });

      const formattedEvents = response.data.map(event => ({
        ...event,
        formattedCreatedAt: format(new Date(event.createdAt), 'dd/MM/yy'),
      }));

      setEvents(formattedEvents);
    }

    getEvents();
  }, [search, type]);

  return (
    <Container>
      <Search placeholder="Buscar evento..." onChange={handleSearch}>
        <Select
          placeholder="Tipo de evento"
          options={[
            { label: 'Subscription', value: 'subscription' },
            { label: 'Doação', value: 'donation' },
            { label: 'Bits', value: 'bits' },
            { label: 'Resgate', value: 'redeem' },
            { label: 'Pedido', value: 'order' },
            { label: 'Sorteio', value: 'giveaway' },
          ]}
          onChange={setType}
        />
      </Search>
      <div className="events">
        {events.map(event => (
          <EventItem key={event._id} {...event} />
        ))}
      </div>
    </Container>
  );
}
