import styled, { css } from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';

export const Container = styled.div`
  background-color: shapesDark;
  user-select: none;

  .dialog {
    .content {
      display: flex;
      flex-direction: column;

      .gold {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .left {
          display: flex;
          align-items: center;

          input {
            margin-left: 1rem;
            background-color: shapes;
            flex: 1;
            font-size: 1.4rem;
          }
        }

        button {
          margin-left: 1rem;
        }
      }

      > * + * {
        margin-top: 2rem;
      }
    }
  }
`;

export const Wrapper = styled.header`
  max-width: 980px;
  margin: 0 auto;
  padding: pageHorizontal;

  height: 7.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: text;
  font-size: 1.4rem;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 2rem;
  }
`;

export const Right = styled.div``;

export const Logo = styled.img`
  height: 32px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const UserMenuContainer = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;

  font-weight: bold;

  > * {
    transition: all 0.2s ${easeOutCirc};
    padding: 0 2px;
    cursor: pointer;

    + * {
      margin-top: 1rem;
    }

    :hover {
      color: primary;
    }
  }
`;

export const Details = styled.div`
  margin-right: 1.6rem;
  margin-left: 4rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Items = styled.div`
  margin-top: 0px;

  display: flex;
  align-items: center;

  .items__item {
    cursor: pointer;
    display: flex;
    align-items: center;

    + div {
      margin-left: 1.4rem;
    }

    svg {
      margin-right: 6px;
    }

    span {
      margin: 0 0.4rem;
    }
  }
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;

  transition: all 0.2s ${easeOutCirc};

  ${({ enabled }) =>
    enabled &&
    css`
      margin-top: 6px;
      visibility: visible;
      opacity: 1;
      height: 1.8rem;
    `}
`;

export const Trade = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: white;
  margin: vertical;
`;

export const Notifications = styled.div`
  cursor: pointer;
  background-color: translucentDark;
  border-radius: default;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  :active {
    transform: translateY(2px);
  }
`;
