import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  FaTicketAlt,
  FaGoogle,
  FaTrophy,
  FaGift,
  FaCheckCircle,
} from 'react-icons/fa';
import _ from 'lodash';
import Confetti from 'react-confetti';
import { Step } from 'react-step-progress-bar';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';

import { Container, Content, ProgressBar } from './styles';

import api from '../../../../services/api';
import { queue } from '../../../../services/snackbar';
import history from '../../../../services/history';
import theme from '../../../../styles/theme';

import sounds from '../../../../utils/sounds';

import UIfx from 'uifx';

export default function Draw() {
  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const [winner, setWinner] = useState(null);
  const [progress, setProgress] = useState(0);

  const [isConfettiVisible, setIsConfettiVisible] = useState(false);
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false);
  const [error, setError] = useState(null);

  const { giveaway_id } = useParams();

  const selectedTicketRef = useRef(null);

  async function handleStart() {
    setStep(2);
    setProgress(50);
  }

  async function handleDraw({ auto }) {
    let ticketNumber;
    setError(null);

    if (auto) {
      ticketNumber = _.random(1, details.giveaway.tickets.length);
    } else {
      if (
        selectedTicketRef.current.value === '' ||
        +selectedTicketRef.current.value < 1 ||
        selectedTicketRef.current.value > details.giveaway.tickets.length
      ) {
        return setError('Ticket inválido.');
      }
      ticketNumber = selectedTicketRef.current.value;
    }

    const tickets = [null, ...details.giveaway.tickets];

    try {
      const response = await api.post(
        `/admin/giveaways/${giveaway_id}/draw/${tickets[ticketNumber].id}`
      );

      setWinner(response.data.user);
      setStep(3);
      setProgress(100);
    } catch (err) {
      console.log(err.response);
    }
  }

  async function handleCancel() {
    try {
      const response = await api.post(`/admin/giveaways/${giveaway_id}/cancel`);
    } catch (err) {}
  }

  function handleConfettiComplete() {
    setIsConfettiEnabled(false);
  }

  function handleSetStep(step) {
    switch (step) {
      case 1:
        setStep(1);
        setProgress(0);
        break;
      case 2:
        setStep(2);
        setProgress(50);
        break;
      case 3:
        setStep(3);
        setProgress(100);
        break;

      default:
        break;
    }
  }

  function handleSend() {
    queue.notify({
      title: <b>Uhuul!</b>,
      body: `Parabéns, ${winner.username}!`,
      icon: <FaCheckCircle size={18} color={theme.colors.success} />,
      actions: [],
    });

    history.push(`/sorteios/${giveaway_id}`);
  }

  useEffect(() => {
    async function getGiveaway() {
      try {
        const response = await api.get(`/admin/giveaways/${giveaway_id}`);

        setDetails(response.data);
        setLoading(false);
      } catch (err) {}
    }

    getGiveaway();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (step !== 3) return;

    setIsConfettiEnabled(true);
    setIsConfettiVisible(true);

    const sound = new UIfx(sounds[_.random(0, sounds.length - 1)], {
      volume: 0.3,
    });

    sound.play();

    setTimeout(() => setIsConfettiVisible(false), 10000);
  }, [step]);

  return (
    !loading && (
      <Container background={details.giveaway.background}>
        <ProgressBar
          color={theme.colors.primary}
          unfilledBackground={theme.colors.shapesDark}
          height={6}
          percent={progress}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                onClick={() => handleSetStep(1)}
                className={`step ${accomplished && 'accomplished'}`}
              >
                <FaGift />
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                onClick={() => handleSetStep(2)}
                className={`step ${accomplished && 'accomplished'}`}
              >
                <FaTicketAlt />
              </div>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <div
                onClick={() => handleSetStep(3)}
                className={`step ${accomplished && 'accomplished'}`}
              >
                <FaTrophy />
              </div>
            )}
          </Step>
        </ProgressBar>

        <Content>
          {step !== 3 && (
            <>
              <header>
                <h1>{details.giveaway.title}</h1>
                <p>
                  <strong className="color-ticket">
                    <FaTicketAlt /> {details.giveaway.tickets.length} tickets
                  </strong>
                  <span>participantes</span>
                </p>
              </header>
              <img src={details.giveaway.image} alt={details.giveaway.title} />
            </>
          )}

          {step === 1 && (
            <>
              <Button
                color={theme.colors.verified}
                text={theme.colors.text}
                onClick={handleStart}
                pulse
              >
                Iniciar sorteio
              </Button>
              <Button
                color={theme.colors.translucentDark}
                text={theme.colors.text}
                onClick={handleCancel}
                pulse
              >
                Cancelar sorteio
              </Button>
            </>
          )}

          {step === 2 && (
            <div className="actions">
              <div className="manual">
                <input ref={selectedTicketRef} placeholder="Ticket" />
                <Button
                  color={theme.colors.primary}
                  text={theme.colors.text}
                  onClick={() => handleDraw({ auto: false })}
                  pulse
                >
                  Sortear
                </Button>
                <Button
                  color={theme.colors.verified}
                  text={theme.colors.text}
                  url={`https://www.google.com/search?q=random+number+1+${details.giveaway.tickets.length}`}
                >
                  <FaGoogle />
                </Button>
              </div>
              {error && <strong className="error">{error}</strong>}
              <h3>OU</h3>
              <Button
                color={theme.colors.translucentDark}
                text={theme.colors.text}
                onClick={() => handleDraw({ auto: true })}
              >
                Sorteio automático
              </Button>
            </div>
          )}

          {step === 3 && winner && (
            <div className="winner">
              <Confetti
                run={isConfettiEnabled}
                recycle={isConfettiVisible}
                onConfettiComplete={handleConfettiComplete}
                width={window.innerWidth}
                height={window.innerHeight}
              />

              <header>
                <p>E o vencedor de</p>
                <h1>{details.giveaway.title}</h1>
                <p>é...</p>
              </header>

              <Avatar
                url={winner.avatar}
                verified={winner.is_verified}
                admin={winner.is_admin}
                size={112}
              />
              <p>
                Parabéns, <strong>{winner.username}</strong>!
              </p>

              <div className="actions">
                <Button
                  color={theme.colors.primary}
                  text={theme.colors.text}
                  onClick={handleSend}
                  pulse
                >
                  Enviar premiação
                </Button>
              </div>
            </div>
          )}
        </Content>
      </Container>
    )
  );
}
