import styled from '@xstyled/styled-components';

import { Tab as MaterialTab } from '@rmwc/tabs';

import theme from '../../../../styles/theme';

export const Container = styled.div`
  font-size: 1.4rem;

  .content {
    padding: 3rem;

    p {
      line-height: 3rem;

      &.center {
        text-align: center;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    > * + * {
      margin-top: 3rem;
    }

    .giveaway {
      display: flex;
      flex-direction: column;
      align-items: center;

      .giveaway_ticket-count {
        margin-bottom: 3rem;
        font-size: 2rem;
        text-align: center;
      }

      .giveaway__unique {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
      }

      h3.or {
        margin: 2rem 0;
      }

      .giveaway__common {
        display: flex;
        flex-direction: column;
        align-items: center;

        .giveaway__common-action {
          display: flex;
          align-items: center;

          input {
            background-color: shapes;
            text-align: center;
            font-size: 1.4rem;
            width: 8rem;

            &.alert {
              border: 2px solid;
              border-color: primary;
            }
          }

          > * + * {
            margin-left: 1rem;
          }
        }
      }

      .giveaway__unique,
      .giveaway__common {
        p {
          margin-bottom: 1rem;
        }
      }
    }

    .verifying {
      padding: verticalHuge;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 2rem;
      }
    }
  }
`;

export const Tab = styled(MaterialTab)`
  --mdc-theme-primary: ${theme.colors.gold};

  flex: 1;

  &.mdc-tab {
    padding: 0 1rem;
    :hover .mdc-tab__text-label {
      color: text;
    }
  }
  &.mdc-tab .mdc-tab__text-label {
    font-size: 1.4rem;
    color: text;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  &.mdc-tab--active .mdc-tab__text-label {
    color: gold;
  }
  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: shapes;
    opacity: 1;
  }
  .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
    border-color: gold;
  }
  .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
    opacity: 0;
  }
`;
