import styled from '@xstyled/styled-components';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    strong {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      > * + * {
        margin-left: 6px;
      }
    }
  }
  .load {
    margin-top: 2rem;
    padding: 1.6rem 0;
  }
  .shop__dialog {
    header {
      display: flex;
      align-items: center;
      padding: 3rem 2.4rem 0;
      .shop__dialog-title {
        font-size: 2.4rem;
        margin: 0;
        padding: 0;
      }
      strong {
      }
    }
    .shop__dialog-content {
      display: flex;
      align-items: center;
      .shop__dialog-content-image {
        flex: 1;
        align-items: stretch;
        img {
          height: 100%;
          width: 100%;
          border-radius: default;
        }
      }
      .shop__dialog-content-info {
        margin-left: 2rem;
        flex: 1;
        line-height: 2.4rem;
      }
    }
    .shop__dialog-actions {
      .alert {
        margin: 0 2rem 3rem;
        letter-spacing: 1.11px;
      }
      > * + * {
        margin-left: 1rem;
        strong {
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
`;

export const ShopGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 2rem;
`;
