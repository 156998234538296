import React, { useEffect, useState } from 'react';

import { FaTicketAlt } from 'react-icons/fa';
import { format } from 'date-fns';

import Button from '../../../components/Button';
import Search from '../../../components/admin/Search';
import ListItem from '../../../components/admin/ListItem';
import Switch from '../../../components/Switch';
import Empty from '../../../components/Empty';

import { Container, List, Status, Filters } from './styles';

import theme from '../../../styles/theme';

import api from '../../../services/api';

export default function Giveaways() {
  const [loading, setLoading] = useState(true);
  const [giveaways, setGiveaways] = useState([]);
  const [filters, setFilters] = useState({
    active: true,
  });
  const [search, setSearch] = useState('');

  function toggleFilters(f) {
    setFilters({
      ...filters,
      [f]: !filters[f],
    });
  }

  useEffect(() => {
    async function getGiveaways() {
      const response = await api.get('/admin/giveaways', {
        params: {
          ...filters,
          search,
        },
      });

      setGiveaways(
        response.data.map(giveaway => ({
          ...giveaway,
          ends_at:
            giveaway.ends_at && format(new Date(giveaway.ends_at), 'dd/MM/yy'),
        }))
      );
      setLoading(false);
    }

    getGiveaways();
  }, [filters, search]);

  return (
    <Container>
      <Search placeholder="Buscar sorteio..." onChange={setSearch}>
        <div className="actions">
          <Button
            to="/admin/sorteios/new"
            color={theme.colors.ticket}
            text={theme.colors.text}
          >
            Novo sorteio
          </Button>
        </div>
      </Search>
      <Filters>
        <Switch
          checked={filters.active}
          onChange={() => toggleFilters('active')}
        >
          Apenas ativos
        </Switch>
      </Filters>
      {!loading && (
        <List>
          {!!giveaways.length ? (
            giveaways.map(giveaway => (
              <ListItem
                key={giveaway.id}
                to={`/admin/sorteios/${giveaway.id}`}
                background={giveaway.background}
              >
                <div className="details">
                  <img src={giveaway.image} alt={giveaway.title} />
                  <div>
                    <h2>{giveaway.title}</h2>
                    <div className="row">
                      <div>
                        <FaTicketAlt size={12} />
                        <span>
                          <strong>{giveaway.tickets.length}</strong>/
                          {giveaway.limit}
                        </span>
                      </div>
                      <strong>&middot;</strong>
                      <Status enabled={giveaway.active}>
                        {giveaway.ends_at ? (
                          <>
                            <span>
                              {giveaway.active
                                ? 'Finaliza em'
                                : 'Finalizado em'}
                            </span>
                            <span>{giveaway.ends_at}</span>
                          </>
                        ) : (
                          <span>
                            Faltam {giveaway.limit - giveaway.tickets.length}{' '}
                            tickets
                          </span>
                        )}
                      </Status>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <Button
                    to={`/sorteios/${giveaway.id}`}
                    color={theme.colors.translucentDark}
                    text={theme.colors.text}
                  >
                    <span>Página do sorteio</span>
                  </Button>
                  {giveaway.active && (
                    <Button
                      to={`/admin/sorteios/${giveaway.id}/sortear`}
                      color={theme.colors.steam}
                      text={theme.colors.text}
                    >
                      <span>Sortear</span>
                    </Button>
                  )}
                </div>
              </ListItem>
            ))
          ) : (
            <Empty />
          )}
        </List>
      )}
    </Container>
  );
}
