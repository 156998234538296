import styled, { css } from '@xstyled/styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  background-color: ${({ color }) => color};
  padding: default;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  ${({ color, transparent }) =>
    transparent &&
    css`
      border-radius: default;
      border: 2px solid;
      border-color: ${color};
      background-color: ${transparentize(0.9, color)};
    `}
`;
