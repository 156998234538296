import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Container, Actions } from './styles';

import api from '../../../services/api';

import Section from './Section';
import Item from './Section/Item';
import { Input } from './Section/Item/Types';

import Button from '../../../components/Button';

import theme from '../../../styles/theme';
import history from '../../../services/history';

export default function Settings() {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const user = useSelector(state => state.user);

  useEffect(() => {
    async function getSettings() {
      const { data } = await api.get('/settings');

      setSettings(data);
      setLoading(false);
    }

    getSettings();
  }, []);

  const handleCopyToken = useCallback(() => {
    const { access_token, refresh_token } = user.twitch;
    navigator.clipboard.writeText(
      JSON.stringify({ access_token, refresh_token }, null, 2)
    );
  }, []);

  return (
    <Container>
      <Section title="Geral" />
      <Section title="Gold" loading={loading}>
        <Item
          label="Valor base"
          description="Valor base do gold pra um usuário comum."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.price}
              onChange={() => {}}
            />
          )}
        </Item>
        <Item
          label="Valor para sub"
          description="Valor base do gold pra um subscribers Tier 1 e Prime."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.subPrice[0]}
              onChange={() => {}}
            />
          )}
        </Item>
        <Item
          label="Valor para super sub"
          description="Valor base do gold pra um subscribers Tier 2."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.subPrice[1]}
              onChange={() => {}}
            />
          )}
        </Item>
        <Item
          label="Valor para mega sub"
          description="Valor base do gold pra um subscribers Tier 3."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.subPrice[2]}
              onChange={() => {}}
            />
          )}
        </Item>
      </Section>

      <Section title="Tickets" loading={loading}>
        <Item
          label="Valor base"
          description="Quantidade de tickets por subscribe."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.price}
              onChange={() => {}}
            />
          )}
        </Item>
        <Item
          label="Valor para sub"
          description="Quantidade de tickets por donate."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.subPrice[0]}
              onChange={() => {}}
            />
          )}
        </Item>
      </Section>

      <Section title="Sorteios" loading={loading}>
        <Item
          label="Tempo para expirar"
          description="Tempo limite para resgate (em dias)."
          loading={loading}
        >
          {!loading && (
            <Input
              type="number"
              value={settings.gold.price}
              onChange={() => {}}
            />
          )}
        </Item>
      </Section>

      <Actions>
        <div className="left">
          <Button
            color={theme.colors.shapesDark}
            text={theme.colors.twitch}
            onClick={handleCopyToken}
          >
            Copiar Token
          </Button>
        </div>
        <div className="right">
          <Button
            color={theme.colors.shapesDark}
            text={theme.colors.text}
            to="/admin"
          >
            Voltar
          </Button>
          <Button
            color={theme.colors.primary}
            text={theme.colors.text}
            onClick={history.goBack}
          >
            Salvar
          </Button>
        </div>
      </Actions>
    </Container>
  );
}
