import styled from 'styled-components';

export const Container = styled.div`
  .actions {
    display: flex;
    align-items: center;
  }
  .events {
    > * + * {
      margin-top: 2rem;
    }
  }
  > * + * {
    margin-top: 2rem;
  }
`;
