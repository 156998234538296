import React from 'react';

import Highlight from '../components/Highlight';

import Button from '../../../components/Button';

import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Time() {
  return (
    <>
      <Helmet
        title="Prazos"
        description="Entenda os prazos de envio de premiações e compras"
      />
      <div className="post">
        <h1>Entenda os prazos de envio de premiações e compras</h1>

        <p>
          Sempre que você for contemplado em um sorteio ou fizer uma compra na
          loja sendo com <strong className="color-gold">gold</strong> ou
          utilizando um <strong className="color-gift">gift</strong>, você
          estará sujeito às mesmas regras de prazos para envio, que são as
          seguintes:
        </p>

        <p>
          Se você <strong>não é</strong> um{' '}
          <strong className="color-verified">usuário verificado</strong>:
        </p>

        <Highlight>
          <ul className="center">
            <li>
              Produtos digitais (isso inclui jogos digitais, entradas de
              eventos, ou qualquer outro produto que não necessite de um envio
              postal convencional) tem até 7 dias para serem entregues após o
              resgate.
            </li>
            <li>
              Produtos físicos (que necessitam de um envio postal convencional)
              tem até 45 dias para serem enviados após o recebimento dos dados
              para envio.
            </li>
          </ul>
        </Highlight>
        <p>
          Se você <strong>é</strong> um{' '}
          <strong className="color-verified">usuário verificado</strong>:
        </p>

        <Highlight>
          <ul className="center">
            <li>
              Produtos digitais (isso inclui jogos digitais, entradas de
              eventos, ou qualquer outro produto que não necessite de um envio
              postal convencional) são entregues{' '}
              <strong>automaticamente</strong> e{' '}
              <strong>instantaneamente</strong> após o resgate.
            </li>
            <li>
              Produtos físicos (que necessitam de um envio postal convencional)
              tem até 5 dias úteis para serem enviados após o recebimento dos
              dados para envio.
            </li>
          </ul>
        </Highlight>

        <Highlight>
          <Button
            to="/como-funciona/conta"
            color={theme.colors.translucentDark}
            text={theme.colors.verified}
          >
            Clique aqui para saber mais sobre usuários verificados
          </Button>
        </Highlight>

        <h3>Premiações erradas ou inválidas</h3>

        <p>
          Algumas premiações, principalmente jogos digitais podem ter problemas
          na hora da ativação ou recebimento. Caso isso ocorra, envie um email
          para <code>sorteios@carlinhostroll.com</code> relatando o erro e
          enviando prints (capturas de tela) para facilitar a resolução.
        </p>

        <p>
          Note que o prazo médio para resolução de qualquer problema é
          indefinido, podendo ser curto ou longo dependendo do erro.
        </p>
      </div>
    </>
  );
}
