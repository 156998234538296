import React, { useEffect, useState } from 'react';

import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaCheckCircle } from 'react-icons/fa';

import { Container } from './styles';

import Empty from '../../../components/Empty';
import ProductItem from '../../../components/account/ProductItem';
import GiveawayItem from '../../../components/account/GiveawayItem';

import api from '../../../services/api';
import { queue } from '../../../services/snackbar';
import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Rewards() {
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);

  const [page, setPage] = useState(1);

  async function handleClaim(rewardId) {
    try {
      const response = await api.post(`/claim/${rewardId}`);

      if (Array.isArray(response.data)) {
        return setRewards(prev =>
          prev.map(reward =>
            reward.id === rewardId
              ? {
                  ...reward,
                  status: 'done',
                  giveaway: {
                    ...reward.giveaway,
                    products: response.data.map((prize, i) => ({
                      ...reward.giveaway.products[i],
                      code: prize.value,
                    })),
                  },
                }
              : reward
          )
        );
      }

      setRewards(prev =>
        prev.map(reward => (reward.id === rewardId ? response.data : reward))
      );

      queue.notify({
        title: <b>Tudo certo!</b>,
        body: 'Agora é só aguardar!',
        icon: <FaCheckCircle size={18} color={theme.colors.success} />,
        actions: [
          {
            title: 'Beleza!',
          },
        ],
      });
    } catch (err) {}
  }

  async function getRewards() {
    try {
      const response = await api.get('/rewards', {
        params: {
          page,
        },
      });

      const formattedRewards = response.data.map(reward => ({
        ...reward,
        formattedCreated: formatDistance(
          new Date(reward.created_at),
          new Date(),
          {
            locale: ptBR,
          }
        ),
      }));

      setRewards(formattedRewards);

      setLoading(false);
    } catch (err) {}
  }

  async function handleLoadMore() {
    setPage(prev => prev + 1);
  }

  useEffect(() => {
    getRewards();
  }, [page]); // eslint-disable-line

  return (
    <>
      <Helmet title="Premios" description="Suas premiações" />
      {!loading && (
        <Container>
          {!!rewards.length ? (
            rewards.map(reward => {
              if (reward.giveaway) {
                const { giveaway, keys } = reward;

                return (
                  <GiveawayItem
                    key={giveaway.id}
                    id={reward.id}
                    status={reward.status}
                    formattedCreated={reward.formattedCreated}
                    expiresAt={reward.expires_at}
                    onClaim={() => handleClaim(reward.id)}
                    keys={keys}
                    {...giveaway}
                  />
                );
              }
            })
          ) : (
            <Empty />
          )}
        </Container>
      )}
    </>
  );
}
