import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .info {
      padding: 1rem 0;
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .profile {
        display: flex;
        flex-direction: column;
        > * + * {
          margin-top: 0.4rem;
        }
      }
      .inventory {
        display: flex;
        .item {
          display: flex;
          align-items: center;
          strong {
            margin-left: 0.4rem;
          }
          span {
            margin: 0 0.5rem;
          }
          + .item {
            margin-left: 1rem;
          }
        }
      }
    }
    .stats {
      display: flex;
      flex-direction: column;
      text-align: right;
      font-size: 1.6rem;
      > * + * {
        margin-top: 1rem;
      }
    }
  }
`;
