import React from 'react';

import MetaTags from 'react-meta-tags';

import Logo from '../../assets/logo.png';

export default function Helmet({ title, description }) {
  return (
    <MetaTags>
      <title>
        {process.env.REACT_APP_NAME} - {title}
      </title>
      <meta property="og:type" content="website" />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={'content'} />
    </MetaTags>
  );
}
