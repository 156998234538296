import React, { useEffect, useState, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import _ from 'lodash';

import UIfx from 'uifx';
import notificationSound from '../../assets/sounds/notification.mp3';

import {
  signInSuccess,
  signOutRequest,
} from '../../store/modules/auth/actions';
import { exchangeRequest } from '../../store/modules/user/actions';

import { FaTicketAlt, FaGift, FaCoins, FaBell, FaBacon } from 'react-icons/fa';

import Tippy from '@tippy.js/react';
import { roundArrow } from 'tippy.js/dist/tippy.esm';

import {
  Container,
  Wrapper,
  Left,
  Right,
  Logo,
  User,
  Details,
  Items,
  Trade,
  UserMenuContainer,
  Notifications,
} from './styles';

import logo from '../../assets/logo.png';

import socket from '../../services/socket';

import { createPopup } from '../../utils';

import theme from '../../styles/theme';

import Avatar from '../Avatar';
import Button from '../Button';
import Dialog from '../Dialog';
import { DialogTitle, DialogContent } from '@rmwc/dialog';

import { LoadingDetails, LoadingAvatar } from './Skeletons';

import history from '../../services/history';

function GoldMenu({ elements, defaults, onExchange }) {
  return (
    <div className="items__item-tippy">
      <p>
        Troque os pontos da live por{' '}
        <strong className="color-gold">golds</strong>.
      </p>

      <p>Atualmente você tem {elements.points} pontos.</p>

      <Trade>
        <Button onClick={onExchange} color={theme.colors.gold}>
          Trocar pontos
        </Button>
      </Trade>
    </div>
  );
}

function TicketsMenu() {
  return (
    <div className="items__item-tippy">
      <p>
        Quantidade de <strong className="color-ticket">tickets</strong>{' '}
        disponíveis.
      </p>
      <p>
        Os <strong className="color-ticket">tickets</strong> são utilizados para
        participar dos sorteios.
      </p>
    </div>
  );
}

function GiftsMenu() {
  return (
    <div className="items__item-tippy">
      <p>
        Quantidade de <strong className="color-gift">gifts</strong> disponíveis.
      </p>
      <p>
        Os <strong className="color-gift">gifts</strong> são utilizados para
        resgatar itens específicos da loja.
      </p>
    </div>
  );
}

function UserMenu({ logout, admin }) {
  return (
    <UserMenuContainer className="items__item-tippy">
      <Link to="/premios">Premios</Link>
      <Link to="/pedidos">Pedidos</Link>
      <Link to="/historico">Histórico</Link>
      <Link to="/conta">Configurações</Link>
      {admin && <Link to="/admin">Painel de Controle</Link>}
      <span onClick={logout}>Sair</span>
    </UserMenuContainer>
  );
}

const notificationFx = new UIfx(notificationSound, {
  volume: 0.3,
});

function Header() {
  const [dialog, setDialog] = useState(false);
  const [goldAmount, setGoldAmount] = useState(0);

  const { signed } = useSelector(state => state.auth);
  const { profile, elements, twitch } = useSelector(state => state.user);
  const { loading, defaults } = useSelector(state => state.info);
  const { verified, verifying } = useSelector(state => state.verification);
  const { details } = useSelector(state => state.settings);

  const dispatch = useDispatch();

  const { giveaway_id } = useParams();

  const [unique, common] = useMemo(() => {
    if (!profile) return [[], []];
    return _.partition(profile.tickets, 'is_unique');
  }, [profile]);

  const goldCost = useMemo(() => {
    if (!defaults) return 0;
    if (!twitch || !twitch.subscription) return defaults.gold.price;
    switch (twitch.subscription.tier) {
      case 'Prime':
      case '1000':
        return defaults.gold.subPrice[0];
      case '2000':
        return defaults.gold.subPrice[1];
      case '3000':
        return defaults.gold.subPrice[2];
      default:
        return defaults.gold.price;
    }
  }, [profile]);

  let loginPopup;

  socket.on('login:success', data => {
    if (!loginPopup) return;
    loginPopup.close();

    dispatch(signInSuccess(data));
  });

  function handleLogin() {
    if (!socket.id) return;
    loginPopup = createPopup(
      `${process.env.REACT_APP_API_URL}/connect/twitch?socketId=${socket.id}`,
      'Login',
      480,
      640
    );
  }

  function handleLogout() {
    dispatch(signOutRequest());
  }

  function handleOpenExchange() {
    setDialog(true);
  }

  async function handleExchange() {
    dispatch(exchangeRequest(goldAmount));
  }

  useEffect(() => {
    if (!profile) return;
    setDialog(false);
  }, [profile]);

  const maxGold =
    (elements && defaults && Math.floor(elements.points / goldCost)) || 0;
  return (
    <Container>
      <Dialog className="dialog" open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle>Comprar Gold</DialogTitle>
        <DialogContent className="content">
          {elements &&
          defaults &&
          elements.points &&
          elements.points >= goldCost ? (
            <>
              <p>
                Você tem <strong>{elements.points} pontos</strong> e pode
                comprar{' '}
                <strong className="color-gold">
                  {maxGold} {maxGold > 1 ? 'golds' : 'gold'}
                </strong>
              </p>
              <div className="gold">
                <div className="left">
                  <span>Quantidade</span>
                  <input
                    value={goldAmount}
                    onChange={e => setGoldAmount(e.target.value)}
                    type="number"
                    min="1"
                  />
                </div>
                <Button
                  onClick={handleExchange}
                  color={theme.colors.gold}
                  disabled={goldAmount < 1}
                  pulse={goldAmount > 0}
                >
                  Comprar
                </Button>
              </div>
            </>
          ) : (
            <>
              Você ainda não tem pontos suficientes para comprar{' '}
              <strong className="color-gold">gold</strong>.
            </>
          )}
        </DialogContent>
      </Dialog>
      <Wrapper>
        <Left>
          <Link to="/">
            <Logo src={logo} />
          </Link>
          <Link to="/como-funciona">
            <strong>Como funciona</strong>
          </Link>
          <Link to="/sorteios">
            <strong>Sorteios</strong>
          </Link>
          <Link to="/loja">
            <strong>Loja</strong>
          </Link>
        </Left>
        <Right>
          {signed ? (
            <User isAdmin={profile.is_admin}>
              <Notifications onClick={() => notificationFx.play()}>
                <FaBell size={14} />
              </Notifications>
              <Details>
                {verifying ? (
                  <LoadingDetails />
                ) : (
                  <>
                    <span className="username">
                      Olá,{' '}
                      <strong className={twitch.subscription && `color-twitch`}>
                        {profile.username}
                      </strong>
                    </span>

                    <Items enabled={details}>
                      <Tippy
                        animation="shift-toward"
                        arrow={roundArrow}
                        content={
                          <GoldMenu
                            elements={elements}
                            defaults={defaults}
                            gold={profile.gold}
                            onExchange={handleOpenExchange}
                          />
                        }
                        className="items__item-tippy"
                        delay={300}
                        inertia
                        interactive
                        theme="default"
                      >
                        <div className="items__item">
                          <FaBacon />{' '}
                          <strong>{elements && elements.points}</strong>{' '}
                          <span>&middot;</span>
                          <FaCoins className="color-gold" />{' '}
                          <strong>{profile.gold}</strong>
                        </div>
                      </Tippy>
                      <Tippy
                        animation="shift-toward"
                        arrow={roundArrow}
                        content={<TicketsMenu />}
                        delay={300}
                        inertia
                        theme="default"
                        hideOnClick="toggle"
                      >
                        <div className="items__item">
                          <FaTicketAlt className="color-ticket" />{' '}
                          <strong>{common.length}</strong> <span>&middot;</span>
                          <FaTicketAlt className="color-twitch" />{' '}
                          <strong>{unique.length}</strong>
                        </div>
                      </Tippy>
                      <Tippy
                        animation="shift-toward"
                        arrow={roundArrow}
                        content={<GiftsMenu />}
                        delay={300}
                        inertia
                        theme="default"
                      >
                        <div className="items__item">
                          <FaGift className="color-gift" />
                          <strong>{profile.gifts.length}</strong>
                        </div>
                      </Tippy>
                    </Items>
                  </>
                )}
              </Details>
              {loading || !verified ? (
                <LoadingAvatar />
              ) : (
                <Tippy
                  animation="shift-toward"
                  arrow={roundArrow}
                  content={
                    <UserMenu logout={handleLogout} admin={profile.is_admin} />
                  }
                  delay={300}
                  inertia
                  interactive
                  theme="default"
                >
                  <Avatar
                    url={profile.avatar}
                    admin={profile.is_admin}
                    verified={profile.is_verified}
                    onClick={() => history.push('/conta')}
                  />
                </Tippy>
              )}
            </User>
          ) : (
            <Button
              color={theme.colors.twitch}
              text={theme.colors.text}
              onClick={handleLogin}
              pulse={!!giveaway_id}
            >
              Entrar com Twitch
            </Button>
          )}
        </Right>
      </Wrapper>
    </Container>
  );
}

export default memo(Header);
