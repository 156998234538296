import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import ImageRenderer from './renderers/ImageRenderer';

import axios from 'axios';

import { format } from 'date-fns';

import { Container } from './styles';

const renderers = { image: ImageRenderer };

export default function Post() {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  const { slug } = useParams();

  useEffect(() => {
    async function getPost() {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/posts?slug=${slug}`
      );

      const [foundPost] = response.data;

      if (!foundPost) {
        setError('Essa página não existe');
      }

      console.log(foundPost);

      setPost(foundPost);
      setLoading(false);
    }

    getPost();
  }, []);

  return loading ? (
    <h1>carregando</h1>
  ) : (
    <>
      {error ? (
        <h2>{error}</h2>
      ) : (
        <Container>
          <div className="header">
            <h1>{post.title}</h1>
            <p className="date">
              {format(new Date(post.created_at), 'dd/MM/yy')}
            </p>
            <img
              className="banner"
              src={`${process.env.REACT_APP_CMS_URL}/${post.image.url}`}
            />
          </div>
          <ReactMarkdown
            className="content"
            renderers={renderers}
            source={post.content}
          />
        </Container>
      )}
    </>
  );
}
