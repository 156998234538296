import React from 'react';

import Highlight from '../components/Highlight';

import Button from '../../../components/Button';

import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Tickets() {
  return (
    <>
      <Helmet
        title="Tickets"
        description="Saiba mais sobre os tickets e purple tickets"
      />

      <div className="post">
        <h1>Sistema de tickets para sorteios</h1>

        <h3>
          <span className="color-ticket">Tickets</span> e{' '}
          <color className="color-twitch">Purple Tickets</color>... Como faço
          para conseguir?
        </h3>

        <p>
          Para entrar em qualquer sorteio da live você precisa de{' '}
          <strong className="color-ticket">tickets</strong>! Você recebe das
          seguintes formas:
        </p>

        <ul>
          <li>
            <h4>Fazendo uma doação</h4>
            <p>
              A cada <strong>R$10</strong> doados na live, você ganha
              automaticamente{' '}
              <strong>
                1 <span className="color-ticket">ticket</span>
              </strong>{' '}
              na sua conta e pode usá-lo para entrar em{' '}
              <strong>qualquer sorteio</strong>. Obviamente estes tickets são
              multiplicados por doações maiores, por exemplo uma doação única de{' '}
              <strong>R$50</strong> gera automaticamente{' '}
              <strong>
                5 <span className="color-ticket">tickets</span>
              </strong>
              .
            </p>
          </li>
          <li>
            <h4>
              Se tornando um <span className="color-twitch">subscriber</span>
            </h4>
            <p>
              Cada vez que você entra como{' '}
              <strong className="color-twitch">subscriber</strong> da live
              (podendo ser{' '}
              <strong>
                Twitch <strong className="color-prime">Prime</strong>
              </strong>
              , sub normal, resub ou sendo presenteado) você ganha{' '}
              <strong className="color-twitch">purple tickets</strong> que podem
              ser utilizados para entrar em qualquer sorteio.
            </p>
          </li>
          <li>
            <h4>
              Dando um <span className="color-twitch">subscriber</span> de
              presente
            </h4>
            <p>
              Ao presentear um ou mais usuários com um sub da live, você ganha
              automaticamente em sua conta um{' '}
              <strong className="color-ticket">ticket</strong> que pode ser
              usado para participar de <strong>qualquer sorteio</strong>.
            </p>
          </li>
          <li>
            <h4>
              Ganhando em <strong>sorteios abertos</strong>
            </h4>
            <p>
              Esporadicamente são realizados sorteios abertos na live com{' '}
              <strong className="color-ticket">ticket</strong> de premiação,
              fiquem ligados!
            </p>
          </li>
        </ul>

        <h3>
          Aumentando suas chances com vários{' '}
          <span className="color-ticket">tickets</span> por sorteio
        </h3>

        <p>
          Todos os sorteios da live aceitam qualquer quantidade de{' '}
          <strong className="color-ticket">ticket</strong> de um mesmo usuário.
          E os <strong className="color-twitch">purple tickets</strong> podem
          ser utilizados <strong>uma vez</strong> por sorteio em quantos
          sorteios quiser. Cada <strong className="color-ticket">ticket</strong>{' '}
          representa um número e uma chance a mais dentro do sorteio.
        </p>

        <Highlight>
          <Button
            to="/como-funciona/sorteios"
            color={theme.colors.translucentDark}
            text={theme.colors.ticket}
          >
            Clique aqui para saber mais sobre os sorteios
          </Button>
        </Highlight>
      </div>
    </>
  );
}
